var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.isUpdate)?_c('div',[_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Meta keywords","label-for":"meta_keywords"}},[_c('ValidationProvider',{attrs:{"name":"meta_keywords","rules":{
                required: false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"id":"meta_keywords","state":errors[0] ? false : null},model:{value:(_vm.form.metaKeyword),callback:function ($$v) {_vm.$set(_vm.form, "metaKeyword", $$v)},expression:"form.metaKeyword"}})]}}],null,false,2376841253)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"Meta description","label-for":"meta_description"}},[_c('ValidationProvider',{attrs:{"name":"meta_description","rules":{
                required: false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_description","state":errors[0] ? false : null},model:{value:(_vm.form.metaDescription),callback:function ($$v) {_vm.$set(_vm.form, "metaDescription", $$v)},expression:"form.metaDescription"}})]}}],null,false,3217315841)})],1),_c('hr')],1):_vm._e(),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"โลโก้","label-for":"logo"}},[_c('ValidationProvider',{attrs:{"name":"logo","rules":_vm.isUpdate ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"logo","accept":"image/*","readonly":"","state":errors[0] ? false : null},model:{value:(_vm.form.logo),callback:function ($$v) {_vm.$set(_vm.form, "logo", $$v)},expression:"form.logo"}})]}}])}),(_vm.form.logo)?_c('b-form-text',[(typeof _vm.form.logo === 'string')?_c('a',{attrs:{"href":_vm.form.logo,"target":"_blank"}},[_vm._v("ดูรูป")]):[_c('a',{attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getDataUrlFromImage(_vm.form.logo)}}},[_vm._v(" ดูรูป ")]),_c('b-link',{staticClass:"text-danger",on:{"click":function($event){_vm.form.logo = null}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")])]],2):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"ไอคอนเว็บ (16x16)","label-for":"favicon"}},[_c('ValidationProvider',{attrs:{"name":"favicon","rules":_vm.isUpdate ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"favicon","accept":"image/*","readonly":"","state":errors[0] ? false : null},model:{value:(_vm.form.favicon),callback:function ($$v) {_vm.$set(_vm.form, "favicon", $$v)},expression:"form.favicon"}})]}}])}),(_vm.form.favicon)?_c('b-form-text',[(typeof _vm.form.favicon === 'string')?_c('a',{attrs:{"href":_vm.form.favicon,"target":"_blank"}},[_vm._v("ดูรูป")]):[_c('a',{attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getDataUrlFromImage(_vm.form.favicon)}}},[_vm._v(" ดูรูป ")]),_c('b-link',{staticClass:"text-danger",on:{"click":function($event){_vm.form.favicon = null}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")])]],2):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"พื้นหลัง","label-for":"bg"}},[_c('b-form-radio-group',{attrs:{"options":_vm.bgTypeOption,"button-variant":"outline-primary","buttons":""},model:{value:(_vm.form.backgroundType),callback:function ($$v) {_vm.$set(_vm.form, "backgroundType", $$v)},expression:"form.backgroundType"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.backgroundType === 'color'),expression:"form.backgroundType === 'color'"}],staticClass:"mt-3",attrs:{"id":"backgroundColor","acp-color":_vm.form.backgroundColor || '#DEDEDE',"acp-show-rgb":"yes","acp-show-hsl":"no","acp-show-hex":"yes","acp-show-alpha":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.backgroundType === 'image'),expression:"form.backgroundType === 'image'"}],staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"bgImage","rules":_vm.isUpdate ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"bgImage","accept":"image/*","readonly":"","state":errors[0] ? false : null},model:{value:(_vm.form.backgroundImage),callback:function ($$v) {_vm.$set(_vm.form, "backgroundImage", $$v)},expression:"form.backgroundImage"}})]}}])}),(_vm.form.backgroundImage)?_c('b-form-text',[(typeof _vm.form.backgroundImage === 'string')?_c('a',{attrs:{"href":_vm.form.backgroundImage,"target":"_blank"}},[_vm._v("ดูรูป")]):[_c('a',{attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getDataUrlFromImage(_vm.form.backgroundImage)}}},[_vm._v(" ดูรูป ")]),_c('b-link',{staticClass:"text-danger",on:{"click":function($event){_vm.form.backgroundImage = null}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")])]],2):_vm._e()],1)],1),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._v(" สีหลัก "),_c('div',{staticClass:"mt-3",attrs:{"id":"primaryColor","acp-color":_vm.form.primaryColor || '#c80000',"acp-show-rgb":"yes","acp-show-hsl":"no","acp-show-hex":"yes","acp-show-alpha":""}})]),_c('div',{staticClass:"col-md-6"},[_vm._v(" สีรอง "),_c('div',{staticClass:"mt-3",attrs:{"id":"secondaryColor","acp-color":_vm.form.secondaryColor || '#a2a2a2',"acp-show-rgb":"yes","acp-show-hsl":"no","acp-show-hex":"yes","acp-show-alpha":""}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }