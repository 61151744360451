<template>
  <b-card>
    <div v-if="isUpdate">
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="Meta keywords"
          label-for="meta_keywords"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="meta_keywords"
            :rules="{
                  required: false,
                }"
        >
          <b-form-tags
              id="meta_keywords"
              v-model="form.metaKeyword"
              :state="errors[0] ? false : null"
          ></b-form-tags>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="Meta description"
          label-for="meta_description"
      >
        <ValidationProvider
            v-slot="{ errors }"
            name="meta_description"
            :rules="{
                  required: false,
                }"
        >
          <b-form-input
              id="meta_description"
              v-model="form.metaDescription"
              :state="errors[0] ? false : null"
          ></b-form-input>
        </ValidationProvider>
      </b-form-group>
      <hr />
    </div>

    <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="โลโก้"
        label-for="logo"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="logo"
          :rules="isUpdate ? null : 'required'"
      >
        <b-form-file
            id="logo"
            v-model="form.logo"
            accept="image/*"
            readonly
            :state="errors[0] ? false : null"
        >
        </b-form-file>
      </ValidationProvider>
      <b-form-text v-if="form.logo">
        <a v-if="typeof form.logo === 'string'" :href="form.logo" target="_blank">ดูรูป</a>
        <template v-else>
          <a
              target="_blank"
              href="#"
              @click.prevent="getDataUrlFromImage(form.logo)"
          >
            ดูรูป
          </a>
          <b-link
              class="text-danger"
              @click="form.logo = null"
          >
            {{ $t('buttons.remove') }}
          </b-link>
        </template>
      </b-form-text>
    </b-form-group>

    <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="ไอคอนเว็บ (16x16)"
        label-for="favicon"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="favicon"
          :rules="isUpdate ? null : 'required'"
      >
        <b-form-file
            id="favicon"
            v-model="form.favicon"
            accept="image/*"
            readonly
            :state="errors[0] ? false : null"
        >
        </b-form-file>
      </ValidationProvider>
      <b-form-text v-if="form.favicon">
        <a v-if="typeof form.favicon === 'string'" :href="form.favicon" target="_blank">ดูรูป</a>
        <template v-else>
          <a
              target="_blank"
              href="#"
              @click.prevent="getDataUrlFromImage(form.favicon)"
          >
            ดูรูป
          </a>
          <b-link
              class="text-danger"
              @click="form.favicon = null"
          >
            {{ $t('buttons.remove') }}
          </b-link>
        </template>
      </b-form-text>
    </b-form-group>

    <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="พื้นหลัง"
        label-for="bg"
    >
      <b-form-radio-group
          v-model="form.backgroundType"
          :options="bgTypeOption"
          button-variant="outline-primary"
          buttons
      >
      </b-form-radio-group>
      <div v-show="form.backgroundType === 'color'" id="backgroundColor" class="mt-3"
           :acp-color="form.backgroundColor || '#DEDEDE'"
           acp-show-rgb="yes"
           acp-show-hsl="no"
           acp-show-hex="yes"
           acp-show-alpha>
      </div>
      <div v-show="form.backgroundType === 'image'" class="mt-2">
        <ValidationProvider
            v-slot="{ errors }"
            name="bgImage"
            :rules="isUpdate ? null : 'required'"
        >
          <b-form-file
              id="bgImage"
              v-model="form.backgroundImage"
              accept="image/*"
              readonly
              :state="errors[0] ? false : null"
          >
          </b-form-file>
        </ValidationProvider>
        <b-form-text v-if="form.backgroundImage">
          <a v-if="typeof form.backgroundImage === 'string'" :href="form.backgroundImage" target="_blank">ดูรูป</a>
          <template v-else>
            <a
                target="_blank"
                href="#"
                @click.prevent="getDataUrlFromImage(form.backgroundImage)"
            >
              ดูรูป
            </a>
            <b-link
                class="text-danger"
                @click="form.backgroundImage = null"
            >
              {{ $t('buttons.remove') }}
            </b-link>
          </template>
        </b-form-text>
      </div>
    </b-form-group>
    <hr />
    <div class="row">
      <div class="col-md-6">
        สีหลัก
        <div id="primaryColor" class="mt-3"
             :acp-color="form.primaryColor || '#c80000'"
             acp-show-rgb="yes"
             acp-show-hsl="no"
             acp-show-hex="yes"
             acp-show-alpha>
        </div>
      </div>
      <div class="col-md-6">
        สีรอง
        <div id="secondaryColor" class="mt-3"
             :acp-color="form.secondaryColor || '#a2a2a2'"
             acp-show-rgb="yes"
             acp-show-hsl="no"
             acp-show-hex="yes"
             acp-show-alpha>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import * as AColorPicker from 'a-color-picker'

export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ตั้งค่าเว็บไซต์',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        logo: null,
        favicon: null,
        metaKeyword: [],
        metaDescription: '',
        siteThemeConfig: {}
      }),
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      bgTypeOption: [
        { text: 'รูป', value: 'image' },
        { text: 'สี', value: 'color' },
      ]
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      logo: this.agentData?.siteThemeConfig?.logoUrl || this.agentData.logo,
      favicon: this.agentData.metaIconSrc,
      metaKeyword: this.agentData.metaKeyword,
      metaDescription: this.agentData.metaDescription,
      backgroundType: this.agentData?.siteThemeConfig?.backgroundType || 'image',
      primaryColor: this.agentData?.siteThemeConfig?.primaryColor || '#c80000',
      secondaryColor: this.agentData?.siteThemeConfig?.secondaryColor || '#a2a2a2',
      tertiaryColor: '#ffffff',
      backgroundImage: null,
      backgroundColor: null
    }
    if (this.form.backgroundType === 'image') {
      this.form.backgroundImage = this.agentData?.siteThemeConfig?.background || null
    } else {
      this.form.backgroundColor = this.agentData?.siteThemeConfig?.background || null
    }
  },
  mounted() {
    AColorPicker
        .from('div#backgroundColor')
        .on('change', (picker, color) => {
          this.form.backgroundColor = color
        })
    AColorPicker
        .from('div#primaryColor')
        .on('change', (picker, color) => {
          this.form.primaryColor = color
        })
    AColorPicker
        .from('div#secondaryColor')
        .on('change', (picker, color) => {
          this.form.secondaryColor = color
        })
  },
  methods: {
    async getDataUrlFromImage(image) {
      if (!image){
        return
      } else if(typeof image === 'string') {
        window.open(image)
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const tmpImage = new Image()
          tmpImage.src = reader.result
          window.open('').document.write(tmpImage.outerHTML);
        };
        reader.readAsDataURL(image);
      }
    }
  }
}
</script>

<style></style>
